@import "utilities/variables";
@import "utilities/mixins";
@import "base/colors/_colors-core";
/* Welcome to Compass. Use this file to write IE specific override styles.
 * Import this file using the following HTML or equivalent:
 * <!--[if IE]>
 *   <link href="/stylesheets/ie.css" media="screen, projection" rel="stylesheet" />
 * <![endif]--> */

@include breakpoint(S) {
  .image-cta {
    height: 10em;
  }
}

//Target IE 10-11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  
   //Footer section
   .newsletter-left {

    p {
    color: $color-white;
    }
  }
}
