//Brand Colours

$color-white: #fff;
$color-slate: #4A4F54;
$color-navy: #00263E;
$color-stone: #D6D1CA;
$color-dusk: #97A3AE;
$color-orange: #EC7700;
$color-green: #8BB174;
$color-yellow: #F9DC5C;
$color-red: #ED254E;
$color-partner-yellow: #F5E85C;

// Colors
$color-body: $color-slate;
$color-primary: $color-navy;

$color-active: $color-orange;
$color-success:$color-green;
$color-error: $color-red;
$color-warning: $color-yellow;